import Swal from 'sweetalert2'

export default class Notify {
  static create (params) {
    const title = typeof params === 'string' ? params : undefined
    const options = {
      position: 'center',
      icon: undefined,
      title,
      showConfirmButton: true,
      timer: false
    }

    if (typeof params === 'object') {
      Object.assign(options, params)
    }

    return Swal.fire(options)
  }
}
